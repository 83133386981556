import React from "react";
import moment from "moment";
import { Link, navigate } from "gatsby";
import posed, { PoseGroup } from "react-pose";
import { FaArrowLeft } from 'react-icons/fa';
import VisibilitySensor from 'react-visibility-sensor';
import styles from './JournalListing.module.scss';
import Arrow from './Arrow';

import suitcaseSvg from '../../static/images/suitcase.svg';

class JournalListing extends React.Component {
  state = {
    visibility: [],
    topRef: undefined
  }

  topRef = React.createRef()

  getTopVisibleIndex() {
    return this.state.visibility.indexOf(true);
  }

  getPostList() {
    const postList = [];
    const { postEdges } = this.props;
    postEdges.forEach(postEdge => {
      postList.push({
        path: postEdge.node.fields.slug,
        tags: postEdge.node.frontmatter.tags,
        cover: postEdge.node.frontmatter.cover,
        title: postEdge.node.frontmatter.title,
        date: postEdge.node.fields.date,
        excerpt: postEdge.node.excerpt,
        timeToRead: postEdge.node.timeToRead,
        readingTime: postEdge.node.fields.readingTime.text,
        locationName: postEdge.node.fields.locationName,
        html: postEdge.node.html,
        coordinates: postEdge.node.frontmatter.coordinates,
        author: postEdge.node.frontmatter.author
      });
    });
    return postList;
  }

  handleSetActivePath = (path) => () => navigate(path);

  setTopRef = (ref) => {
    // console.log("setting ref:", ref);
    if (ref && this.state.topRef !== ref) {
      this.setState({ topRef: ref });
      this.props.onChange(this.getPostList()[this.getTopVisibleIndex()]);
    }
  }

  setVisibility = (index) => (visible) => {
    const visibility = this.state.visibility;
    visibility[index] = visible;
    this.setState({ visibility });
  }

  render() {
    const postList = this.getPostList();
    const { location } = this.props;
    const { pathname } = location;
    const slugMatch = /\/journals\/(.*)/.exec(pathname);
    const slug = slugMatch && `/${slugMatch[1]}`


    const posts = postList;
    // const posts = activePath ? [ postList.find( el => el.path === activePath) ] : postList;
    const topVisibleIndex = this.getTopVisibleIndex();

    return (
      <div className={styles.list}>
        {slug && <button onClick={() => navigate('/journals')} className='debutton'><FaArrowLeft />&nbsp;Back</button>}
        <PoseGroup animateOnEnter={true}>
          {posts.map((post, index) => (
            <VisibilitySensor onChange={this.setVisibility(index)} scrollCheck={true} key={post.path}>
              {({ isVisible }) => {
                const pose = slug ? (slug === post.path ? 'enter' : 'exit') : 'enter'
                return (
                  <JournalEntry
                    key={post.path}
                    className={styles.entry}
                    pose={pose}>
                    <Link to={`/journals/${post.path}`} key={post.title}>
                      {!slug && (topVisibleIndex === index) ?
                        // Was here, causes infinite render loop: ref={ref => this.setTopRef(ref)}
                        <h1 className={styles.title} ref={ref => this.setTopRef(ref)}>{post.title}</h1>
                        :
                        <h1 className={styles.title}>{post.title}</h1>
                      }
                    </Link>
                    <div className={styles.metadata}>
                      {moment(post.date).fromNow()}
                      {post.locationName && ` · ${post.locationName}`}
                      {post.author && ` · ${post.author}`}
                      &nbsp;·&nbsp;
                      {post.readingTime}
                    </div>
                    <JournalContent pose={post.path === slug ? 'open' : 'close'}>
                      <div dangerouslySetInnerHTML={{ __html: post.html }} />
                    </JournalContent>
                    {!slug &&
                      <span>
                        {post.excerpt}
                        <Link to={`/journals/${post.path}`}>read more</Link>
                      </span>
                    }
                  </JournalEntry>
                )
              }}
            </VisibilitySensor>
          ))}
        </PoseGroup>
        {slug
          ? <button onClick={() => navigate('/journals')} className='debutton'><FaArrowLeft />&nbsp;Back</button>
          : <div className={styles.endOfList}>
              <object data={suitcaseSvg} />
              <span>Pack your bags, we're just getting started...</span>
            </div>
        }
      </div>
    );
  }
}

export default JournalListing;

const JournalEntry = posed.div({
  enter: {
    applyAtStart: { display: 'block' },
    height: 'auto',
    opacity: 1,
    delay: 500,
    flip: true,
  },
  exit: {
    applyAtEnd: { display: 'none' },
    height: 1,
    opacity: 0,
  }
});

const JournalContent = posed.div({
  open: {
    applyAtStart: { display: 'block' },
    height: 'auto',
    opacity: 1,
    delay: 500,
    flip: true
  },
  close: {
    applyAtEnd: { display: 'none' },
    height: 1,
    opacity: 0
  } 
})

// const JournalEntry = posed.div({
//   hidden: {
//     // applyAtStart: { display: 'none' },
//     opacity: 0,
//     height: 'auto'
//   },
//   focused: {
//     opacity: 1,
//     height: '100%',
//     delay: 200,
//   },
//   normal: {
//     // applyAtStart: { display: 'block' },
//     opacity: 1,
//     height: 'auto'
//   }
// });
