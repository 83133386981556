import React from 'react';
import { Link } from 'gatsby';
import styles from './navbar.module.scss';
// import AniLink from "gatsby-plugin-transition-link/AniLink";

const Navbar = () => (
  <div className={styles.navbar}>
    <Link to='/' color="brown" className={styles.brand}>
      Hiatus South
    </Link>
    <div className={styles.nav}>
      <Link to='/journals'>Stories</Link>
      {/* <AniLink fade to='/journals' color="brown">The Stories</AniLink> */}
      <Link to='/guides' color="brown">Articles</Link>
      <a href='https://www.instagram.com/hiatussouth/' color="brown">Photos</a>
      <Link to='/data' color="brown">Numbers</Link>
    </div>
  </div>
)

export default Navbar;