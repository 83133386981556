import React from 'react';
import moment from 'moment';
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from '../../layout/withSidebar';
import SidebarMap from '../../components/SidebarMap';
import JournalListing from "../../components/JournalListing";
import SEO from "../../components/SEO/SEO";
import config from "../../../data/SiteConfig";
import { get } from 'lodash/object';

// TODO: break this out into separate pages for articles, using the same map template, rather than navigating around within this one page.
//   Sloppy, fragile code - not just here but in other affected code
class JournalIndex extends React.Component {
  state = {};

  // The top post is the one that's at the top of the listing due to scrolling
  handleSetTopPost = (post) => {
    const { data } = this.props;
    const { edges: postEdges } = data.allMarkdownRemark;
    this.setState({ topPost: postEdges.find( el => el.node.fields.slug === post.path )})
  }

  // The active post is one that's been clicked on, and whose contents are visible
  getActivePost = () => {
    const { data, location } = this.props;
    const { edges: postEdges } = data.allMarkdownRemark;
    const { pathname } = location;
    const slugMatch = /\/journals\/(.*)/.exec(pathname);
    const slug = slugMatch && `/${slugMatch[1]}`

    if (slug) {
      const activePost = postEdges.find( el => el.node.fields.slug === slug);
      return activePost;
    }
  }

  render() {
    const { data, location } = this.props;
    const postEdges = data.allMarkdownRemark.edges;
    const activePost = this.getActivePost();

    const topPost = activePost || this.state.topPost;

    return (
      <Layout sidebar={<SidebarMap asOfDate={moment(get(topPost, 'node.frontmatter.date'))} coordinates={get(topPost, 'node.frontmatter.coordinates')}/>}>
        <Helmet title={config.siteTitle} />
        <SEO />
        <JournalListing onChange={this.handleSetTopPost} postEdges={postEdges} location={location}/>
      </Layout>
    )
  }
}

export default JournalIndex;

export const pageQuery = graphql`
  query JournalQuery {
    allMarkdownRemark(
      limit: 2000, 
      sort: {fields: [fields___date], order: DESC}
      filter: { frontmatter: { category: {eq: "journal" }}}
    ) {
      edges {
        node {
          fields {
            slug
            date
            locationName
            readingTime {
              text
            }
          }
          html
          excerpt(pruneLength: 500)
          timeToRead
          frontmatter {
            title
            tags
            cover
            coordinates
            date
            author
          }
        }
      }
    }
  }
`;
