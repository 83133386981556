
import React, { Component } from "react"
import moment from 'moment';
import {
  ComposableMap,
  ZoomableGroup,
  Geographies,
  Geography,
  Markers,
  Marker,
  Lines,
  Line
} from "react-simple-maps"

import worldData from "../../data/world-50m.json";
import vanSvg from '../../static/images/van.svg';

const wrapperStyles = {
  width: "100%",
  height: "100%",
  maxWidth: 980,
  // margin: "0 auto",
}

const selectedCountries = [
  "ARG", "BOL", "BRA", "CHL", "COL", "ECU",
  "GUY", "PRY", "PER", "SUR", "URY", "VEN", "USA", "PAN", "NIC", "MEX", "CRI", "GTM", "HND", "SLV"
]

const tripPath = [
  { label: 'Santiago', coordinates: [-70.6693, -33.4489], arrivalDate: '2019-01-23' },
  { label: 'Lake District', coordinates: [-71.977774, -39.273116], arrivalDate: '2019-02-13', curveStyle: 'down', xOffset: -110, yOffset: -10 },
  { label: 'Patagonia', coordinates: [-72.30051, -51.308688], arrivalDate: '2019-03-11', curveStyle: 'down', xOffset: -100, yOffset: -10 },
  { label: 'Ushuaia', coordinates: [-68.323974609375, -54.826007], arrivalDate: '2019-04-01', curveStyle: 'down', xOffset: 90, yOffset: -10 },
  { label: 'Buenos Aires', coordinates: [-58.260498046875, -34.74161249883173], curveStyle: 'down', arrivalDate: '2019-04-08', xOffset: 70, yOffset: -50 },
  { label: 'Montevideo', coordinates: [-56.171133, -34.892528], arrivalDate: '2019-04-13', xOffset: 90, yOffset: 20 },
  { label: 'Cordoba', coordinates: [-64.176782, -31.427824], arrivalDate: '2019-04-14', curveStyle: 'down', xOffset: 85, yOffset: -30 },
  { label: 'Potosi', coordinates: [-65.750880, -19.589423], arrivalDate: '2019-04-16', curveStyle: 'down', xOffset: 40, yOffset: -50 },
  { label: 'Salar de Uyuni', coordinates: [-67.447776, -20.303182], arrivalDate: '2019-04-18', curveStyle: 'down', xOffset: -130, yOffset: -10 },
  { label: 'San Pedro', coordinates: [-68.1996, -22.9105], arrivalDate: '2019-04-21', curveStyle: 'up', xOffset: -110, yOffset: -10 },
  { label: 'Santiago', coordinates: [-70.6693, -33.4489], arrivalDate: '2019-04-25' },
  { label: 'Lima', coordinates: [-76.871248, -12.100393], arrivalDate: '2019-05-03', curveStyle: 'up', xOffset: -60, yOffset: -10 },
]

const buildCurves = (start, end, line) => {
  const x0 = start[0];
  const x1 = end[0];
  const y0 = start[1];
  const y1 = end[1];
  const curve = {
    up: `${x1} ${y0}`,
    down: `${x0} ${y1}`
  }[line.curveStyle];

  return `M ${start.join(' ')} Q ${curve} ${end.join(' ')}`;
}

class SidebarMap extends Component {
  render() {
    // const asOfDate = moment(this.props.asOfDate); // eslint-disable-line
    const { asOfDate, coordinates } = this.props;
    const currentLocation = { coordinates };

    console.log("current coordinates are ", coordinates);

    return (
      <div style={wrapperStyles}>
        <ComposableMap
          projectionConfig={{ scale: 1000 }}
          width={700}
          height={1000}
          style={{
            width: "100%",
            height: "100%"
          }}
        >
          <ZoomableGroup center={[-60, -25]} zoom={1} disablePanning>
            <Geographies geography={worldData}>
              {(geographies, projection) => {
                return geographies.map( geography=>
                  selectedCountries.includes(geography.id) && (
                    <Geography
                      key={geography.id}
                      geography={geography}
                      projection={projection}
                      style={{
                        default: {
                          fill: "#fff8f0",
                          stroke: "#607D8B",
                          strokeWidth: 0.75,
                          outline: "none",
                        },
                        hover: {
                          fill: "##fff8f0",
                          stroke: "#607D8B",
                          strokeWidth: 0.75,
                          outline: "none",
                        },
                        pressed: {
                          fill: "#FF5722",
                          stroke: "#607D8B",
                          strokeWidth: 0.75,
                          outline: "none",
                        },
                      }}
                    />
                  )
                )
              }}
            </Geographies>
            <Lines>
              {tripPath.map((el, index) => {
                if (index > 0) {
                  const stroke = moment(el.arrivalDate, 'YYYY-MM-DD') <= asOfDate ? "rgba(255,0,0,0.6)" : "rgba(0,0,0,0.6)";
                  return (
                    <Line
                      key={index}
                      line={{
                        coordinates: {
                          start: tripPath[index - 1].coordinates,
                          end: el.coordinates
                        },
                        curveStyle: el.curveStyle || 'down'
                      }}
                      style={{
                        default: {
                          stroke,
                          fill: 'none',
                          strokeWidth: 5,
                          strokeDasharray: '4'
                        },
                        hover: { stroke, fill: "none" },
                        pressed: { stroke, fill: "none" },
                      }}
                      buildPath={buildCurves}
                    />)
                }
              })}
            </Lines>
            <Markers>
              {tripPath.map((marker, i) => (
                <Marker
                  key={i}
                  marker={marker}
                  style={{
                    default: { stroke: "#842" },
                    hover: { stroke: "#FF5722" },
                    pressed: { stroke: "#FF5722" },
                  }}
                >
                  <g transform="translate(-24, -48), scale(2)">
                    <path
                      fill="transparent"
                      strokeWidth="2"
                      strokeLinecap="square"
                      strokeMiterlimit="10"
                      strokeLinejoin="miter"
                      d="M20,9c0,4.9-8,13-8,13S4,13.9,4,9c0-5.1,4.1-8,8-8S20,3.9,20,9z"
                    />
                    <circle
                      fill="none"
                      strokeWidth="2"
                      strokeLinecap="square"
                      strokeMiterlimit="10"
                      strokeLinejoin="miter"
                      cx="12"
                      cy="9"
                      r="3"
                    />
                  </g>
                  <text
                    textAnchor="middle"
                    x={marker.xOffset || 0}
                    y={marker.yOffset || -55}
                    style={{
                      fontFamily: "quintessentialregular, Roboto, sans-serif",
                      fill: "#842",
                      stroke: "none",
                      fontSize: '2rem',
                      textShadow: '0px 0px 2px #fff8f0'
                    }}
                  >
                    {marker.label}
                  </text>
                </Marker>
              )).concat(
                // This is the van icon
                coordinates 
                ? (
                  <Marker
                    key='currentLocation'
                    marker={currentLocation}
                    style={{
                      default: { stroke: "#842" },
                      hover: { stroke: "#FF5722" },
                      pressed: { stroke: "#FF5722", outline: 'none' },
                    }}
                  >
                    <g 
                      transform="translate(-40,10) scale(0.005,-0.005)"
                      fill="#000"
                      stroke="#f00"
                    >
                      <path d="M4530 7599 c-740 -25 -1711 -148 -2120 -269 -221 -66 -377 -165 -538
                      -342 -32 -35 -50 -48 -71 -48 -36 0 -43 -8 -28 -31 9 -14 8 -25 -5 -51 -50
                      -107 -775 -1420 -803 -1456 -34 -44 -40 -64 -67 -222 -12 -70 -15 -75 -40 -78
                      -36 -4 -39 11 -36 178 l3 135 32 3 c19 2 37 11 43 21 10 21 -33 441 -50 479
                      -9 21 -15 23 -53 17 -64 -11 -67 -16 -67 -122 0 -66 -4 -95 -13 -97 -6 -3 -24
                      -7 -39 -10 -32 -7 -52 -38 -43 -66 3 -10 20 -27 38 -38 21 -12 45 -43 68 -88
                      l37 -69 6 -166 c5 -141 3 -169 -9 -179 -11 -9 -15 -34 -15 -92 0 -65 4 -84 22
                      -108 26 -35 34 -37 41 -10 3 12 14 20 25 20 28 0 36 -43 34 -184 l-2 -120 -75
                      -150 c-142 -285 -197 -529 -196 -859 0 -251 42 -470 125 -665 20 -46 36 -90
                      36 -97 0 -8 -21 -15 -59 -19 l-60 -7 -5 -42 c-3 -23 -7 -53 -8 -67 -2 -17 -10
                      -26 -25 -28 -17 -2 -28 8 -49 45 -16 26 -33 51 -39 55 -19 14 -55 8 -75 -12
                      -27 -27 -26 -88 1 -350 l22 -205 -31 -98 c-18 -53 -32 -109 -32 -122 0 -50 60
                      -183 104 -232 42 -46 44 -52 51 -131 8 -94 31 -135 77 -135 60 1 200 26 214
                      40 18 18 18 18 249 -40 99 -26 199 -49 222 -53 24 -3 47 -12 51 -18 5 -6 20
                      -42 32 -81 130 -403 386 -613 810 -666 142 -17 513 -5 612 20 192 49 361 186
                      505 408 l53 83 453 0 c530 0 731 11 1116 61 162 21 170 20 171 -23 0 -67 111
                      -333 179 -431 160 -234 404 -351 771 -372 140 -8 445 9 520 29 412 113 739
                      687 740 1304 0 74 8 99 25 72 24 -38 89 24 99 95 6 36 11 45 22 41 9 -3 576
                      124 1262 284 l1248 289 65 -15 65 -15 12 -157 c6 -86 21 -195 32 -241 43 -178
                      138 -325 243 -375 149 -72 547 -99 662 -45 66 31 128 91 189 182 125 188 201
                      442 227 757 6 80 13 146 14 148 2 2 100 -35 218 -82 253 -101 316 -119 461
                      -136 103 -13 110 -12 122 5 17 24 18 64 2 69 -7 2 -67 11 -134 20 -126 16
                      -191 33 -177 46 4 4 46 15 93 24 159 31 155 29 189 98 36 71 39 116 14 195
                      l-17 54 52 102 c58 113 71 173 52 233 -28 86 -76 122 -191 145 -35 8 -66 16
                      -69 20 -3 4 1 135 9 290 12 233 12 325 2 509 -6 124 -16 313 -21 420 -6 107
                      -17 283 -27 390 -9 107 -15 206 -14 220 2 14 3 57 4 97 2 60 -2 76 -20 101
                      -14 20 -27 62 -38 123 -84 498 -197 906 -293 1060 -12 21 -19 44 -16 53 4 11
                      1 16 -10 16 -9 0 -33 15 -53 33 -107 94 -402 228 -643 291 -308 80 -768 155
                      -1200 196 -1940 180 -4111 279 -5270 239z m7702 -4165 c69 -35 75 -100 22
                      -207 -50 -100 -48 -98 -96 -81 -22 8 -51 14 -65 14 -13 0 -26 7 -30 16 -6 16
                      32 258 44 277 7 12 90 -1 125 -19z m-452 -614 c102 -16 107 -18 118 -47 5 -16
                      13 -36 17 -45 4 -11 3 -18 -4 -18 -22 0 -271 102 -271 111 0 12 58 11 140 -1z"
                      />
                    </g>
                  </Marker>
              )
                : []
              )}
            </Markers>

          </ZoomableGroup>
        </ComposableMap>
      </div>
    )
  }
}

export default SidebarMap
