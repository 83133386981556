import React from 'react';
import Helmet from "react-helmet";
import config from "../../data/SiteConfig";
import Navbar from '../components/Navbar';
import styles from "./withSidebar.module.scss";

const withSidebar = ({ sidebar, children }) => (
  <div className={styles.page + ' ' + styles.flexStretch}>
    <Navbar />
    <div className={styles.sidebar}><div className={styles.map}>{sidebar}</div></div>
    <div className={styles.content}>{children}</div>
  </div>
)

export default withSidebar;